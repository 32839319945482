@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

/* Hide the default file input */
input[type="file"] {
  display: none;
}

/* Style the label to look like a button */
.custom-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.custom-file-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

